<template>
  <vs-tabs v-model="activeTab" :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">

    <!-- GENERAL -->
    <vs-tab icon-pack="feather" icon="icon-user" :label="!isSmallerScreen ? $t('General') : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <user-settings-general />
      </div>
    </vs-tab>
    <vs-tab v-if="activeUserInfo.userRole==='merchant'" icon-pack="feather" icon="icon-briefcase" :label="!isSmallerScreen ? $t('Account Details') : ''">
      <div class="md:ml-4 md:mt-0 mt-4 ml-0">
        <tax-profile :merchantStatus="merchantStatus"/>
      </div>
    </vs-tab>
    <vs-tab v-if="activeUserInfo.userRole==='merchant'" icon-pack="feather" icon="icon-link-2" :label="!isSmallerScreen ? $t('Merchant') : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <user-merchant />
      </div>
    </vs-tab>
    <vs-tab icon-pack="feather" icon="icon-lock" :label="!isSmallerScreen ? $t('Change Password') : ''">
      <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
        <user-settings-change-password />
      </div>
    </vs-tab>
    <vs-tab v-if="activeUserInfo.userRole==='merchant' && haveSubAccounts" icon-pack="feather" icon="icon-users" :label="!isSmallerScreen ? $t('Sub Accounts') : ''">
      <div class="md:ml-4 md:mt-0 mt-4 ml-0">
        <sub-accounts :childMerchants="childMerchants" />
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import UserSettingsGeneral from './UserSettingsGeneral.vue'
import UserSettingsChangePassword from './UserSettingsChangePassword.vue'
import UserMerchant from './UserMerchant.vue'
import TaxProfile from '../../merchant/TaxProfile.vue'
import SubAccounts from '../../merchant/SubAccounts.vue'
import { sendRequest } from '../../../http/axios/requestHelper'
import { common } from '../../admin/Orders.vue'

export default {
  components: {
    UserSettingsGeneral,
    UserSettingsChangePassword,
    UserMerchant,
    TaxProfile,
    SubAccounts
  },
  data () {
    return {
      activeTab: 0,
      activeTabNameDic: {'general': 0, 'tax-profile': 1, 'merchant': 2, 'change-password': 3, 'sub-accounts': 4},
      activeTabDic: {0: 'general', 1: 'tax-profile', 2: 'merchant', 3: 'change-password', 4: 'sub-accounts'},
      haveSubAccounts: JSON.parse(localStorage.getItem('haveSubAccounts')),
      childMerchants: [],
      merchantID: '',
      merchantStatus: ''
    }
  },
  watch: {
    '$route.params.tab' () {
      this.$router.push({
        params: {
          tab: this.activeTabDic[this.activeTab]
        }
      }).catch(() => {})
    },
    'activeTab' () {
      this.$router.push({
        params: {
          tab: this.activeTabDic[this.activeTab]
        }
      }).catch(() => {})
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    },
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    loadChildMerchants () {
      sendRequest(true, false, this, `api/v1/merchants/${this.merchantID}/sub-merchants/`, 'get', null, true, 
        (response) => {
          this.childMerchants = response.data
        }
      )
    }
  },
  created () {
    this.activeTab = this.activeTabNameDic[this.$route.params.tab]
    if (this.$store.state.AppActiveUser.userRole === 'merchant') {
      const merchantData = common.checkMerchantInLocalStorage(this)
      let user = {}
      merchantData.then(results => {
        user = results.merchantData
        this.merchantID = user.id
        this.loadChildMerchants()
        this.merchantStatus = user.status
      })
    }
  }
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
