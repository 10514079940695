<template>
  <div class="grid xl:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5">
    <div v-for="(childMerchant, index) in childMerchants" :key="index" class="col-span-1 p-5 border-solid border border-grey-light rounded-xl">
      <span class="material-icons-outlined mb-5 text-primary">storefront</span>
      <p class="text-lg text-black mb-1">{{ childMerchant.name }}</p>
      <div class="flex items-center gap-2">
        <p>{{ 'Account ID' }} #{{ childMerchant.id }}</p>
        <p class="flex items-center">
          <span @click="copied(childMerchant.id, 'accountId')" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer">content_copy</span>
          <span v-if="idCopied && childMerchant.id === dataCopied" class="text-xs font-medium">{{ $t('Copied') }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import i18nData from '../../i18n/i18nData'

export default {
  props: ['childMerchants'],
  data () {
    return {
      idCopied: false,
      dataCopied: ''
    }
  },
  methods: {
    async copied (data, type) {
      try {
        this.dataCopied = data
        await navigator.clipboard.writeText(data)
        if (type === 'accountId')  this.idCopied = true
        setTimeout(() => {
          if (type === 'accountId') this.idCopied = false      
        }, 750)
      } catch ($e) {
        this.$vs.notify({
          color:'dark',
          title:i18nData[this.$i18n.locale]['cannot copy!'],
          position: 'bottom-center'
        })
      }
    }
  }
}
</script>