<template>
  <shipblu-card class="mb-6 mt-2 p-5">
    <div class="ml-auto mr-auto text-center justify-center">
      <img class="ml-auto mr-auto mb-4 h-24 w-24" :src="merchantData.logo" />
    </div>
      <div class="vx-row mb-base">
        <div class="vx-col w-1/2 mb-base">
          <label class="font-semibold">Store Name</label>
          <h5 class="w-full font-semibold">{{merchantData.name}}</h5>
        </div>
        <div class="vx-col w-1/2 mb-base">
          <label class="font-semibold">Account Number</label>
          <h5 class="w-full font-semibold">{{merchantData.id}}</h5>
        </div>
        <div class="vx-col w-1/2 mb-base">
          <label class="font-semibold">Store URL</label>
          <h5 class="w-full font-semibold">{{merchantData.store_url}}</h5>
        </div>
        <div class="vx-col w-1/2 mb-base">
          <label class="font-semibold">Phone Number</label>
          <h5 class="w-full font-semibold">{{merchantData.store_phone}}</h5>
        </div>
        <div class="vx-col w-1/2 mb-base">
          <label class="font-semibold">Store Email</label>
          <h5 class="w-full font-semibold">{{merchantData.store_email}}</h5>
        </div>
      </div>
  </shipblu-card>
</template>

<script>
import common from '../../../assets/utils/common.js'
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'

export default {
  data () {
    return {
      merchantData: {}
    }
  },
  components: {
    ShipbluCard
  },
  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    merchantData.then(results => {
      this.merchantData = results.merchantData
    })
  }
}
</script>
