<template>
  <div class="relative">
    <div class="vs-con-loading__container relative" :class="selectedFlag ?'cursor-pointer' : 'cursor-not-allowed'" id="print-btn-loading">
      <div class="flex pl-4 gap-1 pr-10 py-2 rounded items-center h-btn" :class="selectedFlag ? 'text-white bg-primary rounded font-semibold': 'font-semibold bg-cold-morning text-silver rounded'" @click="printLabels()">
        <feather-icon icon="PrinterIcon" svgClasses="w-4 h-4"></feather-icon>
        <span class="text-sm">{{ $t('Print Label') }}</span>
      </div>
      <div class="print-dropdown color-icon flex items-center" :class="selectedFlag ? 'text-white' : 'text-silver'" @click="selectedFlag ? showPrintDropdown = !showPrintDropdown : ''">
        <span class="material-icons-outlined text-xl leading-none">keyboard_arrow_down</span>
      </div>
    </div>
    <div v-show="showPrintDropdown" class="dropdown py-1 px-4 rounded text-blue-900">
      <p @click="printLabels('&one_per_page=true')" class="cursor-pointer text-sm hover:text-primary">{{ $t('One per page') }}</p>
      <p v-if="$store.state.AppActiveUser.userRole === 'merchant'" @click="printLabels('&thermal=true')" class="cursor-pointer mt-2 normal-case text-sm hover:text-primary">{{ $t('Thermal print') }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import JsBarcode from 'jsbarcode'
import i18nData from '../../../i18n/i18nData'
import common from '../../../assets/utils/common'

export default {
  props: ['selected', 'checkAll', 'filters', 'printLableStatuses'],
  data () {
    return {
      selectedFlag: false,
      showPrintDropdown: false,
      merchant: {}
    }
  },
  watch: {
    selected (val) {
      this.showPrintDropdown = false
      if (val.length > 0) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
    },
    checkAll (val) {
      this.$emit('checkAll', val)
    }
  },
  methods: {
    printLabels (query) {
      if (this.$store.state.AppActiveUser.userRole === 'merchant' && this.merchant.has_tax_profile === false) {
        this.$vs.dialog({
          color: 'danger',
          title: i18nData[this.$i18n.locale]['Oops!'],
          text: i18nData[this.$i18n.locale]['You don\'t have a tax profile to perform this action!'],
          acceptText: i18nData[this.$i18n.locale]['Choose Account type'],
          accept: this.goToAddTaxProfile
        })
      } else {
        this.showPrintDropdown = false
        const dataFromFrontEnd = []
        const dataToBackEnd = []
        this.selected.forEach(element => {
          if (element.name || element.tracking_number[0] === '4') {
            dataFromFrontEnd.push(element)
          } else {
            dataToBackEnd.push(element)
          }
        })
        if (dataToBackEnd.length > 0 || (query && query.includes('status'))) {
          this.$vs.loading({
            background: this.backgroundLoading,
            color: this.colorLoading,
            container: '#print-btn-loading',
            scale: 0.45
          })
          axios({
            url: `${process.env.VUE_APP_API_URL}/api/v1/orders/shipping-label/?type=pdf&tracking_numbers=${this.checkAll ? '' : `${dataToBackEnd.map(item => item.tracking_number)}`}${query ? query : ''}${this.checkAll ? `&status=${this.printLableStatuses.some(item => this.filters.includes(item)) && this.filters.length === 1 ? this.filters[0] : ''}` : ''}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
          }).then((response) => {
            if (this.$store.state.AppActiveUser.userRole === 'merchant' && this.filters.length > 0 && this.checkAll) {
              this.$vs.notify({
                color:'success',
                title: i18nData[this.$i18n.locale]['Success'],
                text: i18nData[this.$i18n.locale]['we will generate orders label and sent to you notification after finish it to download it.'],
                position: 'top-center'
              })
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', 'ShipBlu AWBs.pdf')
              document.body.appendChild(link)
              link.click()
            }
            this.$vs.loading.close('#print-btn-loading > .con-vs-loading')
          }).catch((error) => {
            if (error && error.response && error.response.status === 400 && error.response.status) {
              this.$vs.notify({
                color:'danger',
                title: i18nData[this.$i18n.locale]['Failed'],
                text: i18nData[this.$i18n.locale]['Cannot generate a shipping label for unzoned orders.'],
                position: 'top-center'
              })
            }
            this.$vs.loading.close('#print-btn-loading > .con-vs-loading')
          })
        }
        if (dataFromFrontEnd.length > 0 && !this.checkAll) {
          this.selected = dataFromFrontEnd
          JsBarcode('.barcode').init()
          window.print()
        }
      }
    },
    goToAddTaxProfile () {
      this.$router.push({
        name: 'merchant-profile',
        params: {
          tab: 'tax-profile'
        }
      }).catch(() => {})
    }
  },
  created () {
    if (this.$store.state.AppActiveUser.userRole === 'merchant') {
      const merchantData = common.checkMerchantInLocalStorage(this)
      merchantData.then(results => {
        this.merchant = results.merchantData
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.print-dropdown {
  position: absolute;
  top: 50%;
  right: 4px;
  user-select: none;
  transform: translateY(-50%);
}
.dropdown {
  position: absolute;
  z-index: 991;
  top: 40px;
  background: #fff;
  box-shadow: 0px 2px 16px rgba(157, 173, 194, 0.32);
  border: 1px solid rgba(0,0,0,.1);
  user-select: none;
  &::before {
    content: "";
    position: absolute;
    top: -5px;
    left: 6px;
    border-left: 7.5px solid transparent;
    border-right: 7.5px solid transparent;
    border-bottom: 7.5px solid #fff;
    pointer-events: none;
  }
}

</style>