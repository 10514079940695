<template>
  <div class="shipments-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Delivery Orders')}}</h2>
      </div>
    </div>
    <div class="md:flex md:flex-wrap grid grid-cols-2 gap-2 items-center whitespace-nowrap md:mb-6 mb-3">
      <print-label-button :selected="selected"></print-label-button>
      <download-button :selected="selected"></download-button>
      <cancel-shipments-button :selected="selected" @selected="selected = $event" @loadShipments="loadShipments"></cancel-shipments-button>
      <export-button></export-button>
    </div>
    <shipblu-table
      :sst="true"
      orders
      multiple
      v-model="selected"
      :max-items="maximumItems"
      @searchDate="handleSearchDate"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      searchDate
      search
      pagination
      :data="shipments"
      :tableLoader="tableLoader"
    >
      <template slot="header">
        <div class="lg:w-auto w-full md:mt-0 lg:flex grid grid-cols-3 gap-2 items-center">
          <table-filter icon="BarChart2Icon" filterLabel="Status" :filters="filters" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"/>
          <date-filter filterLabel="date"  @searchDate="handleSearchDate" ref="filterDateModal" />
        </div>
      </template>

      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Received')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole === 'admin'">{{$t('RTO Requested')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole === 'admin'">{{$t('Delivery Attempts')}}</shipblu-th>
        <shipblu-th>{{$t('Packages')}}</shipblu-th>
        <shipblu-th>{{$t('Package Size')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Delivered On')}}</shipblu-th>
        <shipblu-th class="flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-delivery-orders`, params: { type: 'delivery-orders', orderID: data[indextr].id}}">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start sm:justify-start justify-center">
                {{ data[indextr].tracking_number }}
                <span @click="copied(data[indextr].tracking_number)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
              <p class="sm:text-left text-center sm:mt-2 mt-1 text-sm" style="color: #F68B26;">{{ data[indextr].on_hold ? 'On Hold' : '' }} {{ data[indextr].rto_requested && data[indextr].on_hold ? ' - ' : '' }} {{ data[indextr].rto_requested ? 'RTO' : '' }}</p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].createdOn">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Created')}}</p>
            <p class="sm:text-base text-sm">
              {{ common.splitYearOfDate(data[indextr].created) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].merchant">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Merchant')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].merchant.name }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].customerInfo">
            <div v-on:click.stop>
              <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Customer')}}</p>
              <p class="sm:text-base text-sm">{{ data[indextr].customer.full_name }}</p>
              <p class="text-sm flex gap-1 sm:text-secondary sm:mt-2 mt-1">
                {{ data[indextr].customer.phone }}
                <span @click="copied(data[indextr].customer.phone)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].location">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Location')}}</p>
            <p class="sm:text-base text-sm">{{ $t(data[indextr].customer.address.zone.name) }}</p>
            <p class="text-sm flex gap-1 sm:text-secondary sm:mt-2 mt-1">{{ $t(data[indextr].customer.address.zone.city.name) }}</p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].pickupDate">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Received')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].pickup_date ? new Date(data[indextr].pickup_date).toLocaleDateString('fr-CA') : ''}}
            </p>
          </shipblu-td>

          <shipblu-td class="order-15" v-if="$store.state.AppActiveUser.userRole === 'admin'" :data="data[indextr].rtoRequested">
            <template v-if="data[indextr].rto_requested">
              <div class="bg-danger h-3 w-3 inline-block rounded-full mr-2"></div>
              <span>RTO</span>
            </template>
          </shipblu-td>

          <shipblu-td class="order-11" v-if="$store.state.AppActiveUser.userRole === 'admin'" :data="data[indextr].deliveryAttempted">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Attempts')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].delivery_attempts}} / 3
            </p>
          </shipblu-td>

          <shipblu-td class="order-13" :data="data[indextr].packages">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Packages')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].packages.length }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-14" :data="data[indextr].packages">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Package Size')}}</p>
            <p class="sm:text-base text-sm" v-for="packageItem in data[indextr].packages" :key="packageItem.index">
              {{packageItem.package.package_size.short_code}}
            </p>
          </shipblu-td>

          <shipblu-td class="order-12" :data="data[indextr].status" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;" @click="removeAttribute($event), openNotificationFailureData(data[indextr])">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>
          
          <shipblu-td class="order-9" :data="data[indextr].deliveredOn">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Delivered On')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].delivered_date && data[indextr].status === 'delivered' ? new Date(data[indextr].delivered_date).toLocaleDateString('fr-CA') : '' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right" @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span> 
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="viewShipment(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EyeIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('View')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                  v-if="data[indextr].status === 'created' || data[indextr].status === 'pickup_requested'"
                    @click="cancelShipment(data[indextr],'cancel this order?' , 'cancelled')"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="XCircleIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Cancel Shipment')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    v-if="$store.state.AppActiveUser.userRole === 'admin'"
                    @click="blockCustomer(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="UserXIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Block Customer')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-cursor-pagination :maximumItems="maximumItems" :paginationObj="paginationObj" @changePageSize="changePageSize" @changePageNumber="changePageNumber($event)" />
    <block-customer :blockCustomerModal="blockCustomerModal" @blockCustomerModal="blockCustomerModal = $event" :data="customer" @loadShipments="loadShipments"></block-customer>
  </div>
</template>

<script>
import common  from '@/assets/utils/common'
import BlockCustomer from '../admin/components/BlockCustomer.vue'
import i18nData from '../../i18n/i18nData.js'
import {sendRequest} from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ExportButton from '../headOfFleet/components/ExportButton.vue'
import PrintLabelButton from '../headOfFleet/components/PrintLabelButton.vue'
import CancelShipmentsButton from './components/CancelShipmentsButton.vue'
import TableFilter from '../merchant/components/TableFilter.vue'
import ShipbluCursorPagination from '../../layouts/components/ShipbluCursorPagination.vue'
import DownloadButton from './components/DownloadButton.vue'
import DateFilter from '../merchant/components/DateFilter.vue'

export default {
  props: ['userRole'],
  data () {
    return {
      common,
      type: 'Add',
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      shipments: [],
      statusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'created',
          value: 'created'
        },
        {
          name: 'pickup requested',
          value: 'pickup_requested'
        },
        {
          name: 'out for pickup',
          value: 'out_for_pickup'
        },
        {
          name: 'pickup rescheduled',
          value: 'pickup_rescheduled'
        },
        {
          name: 'picked up',
          value: 'picked_up'
        },
        {
          name: 'in transit & en route',
          value: 'in_transit, en_route'
        },
        {
          name: 'out for delivery',
          value: 'out_for_delivery'
        },
        {
          name: 'delivery attempted',
          value: 'delivery_attempted'
        },
        {
          name: 'delivered',
          value: 'delivered'
        },
        {
          name: 'RTO Requested',
          value: 'rto_requested'
        },
        {
          name: 'On Hold',
          value: 'on_hold'
        },
        {
          name: 'out for return',
          value: 'out_for_return'
        },
        {
          name: 'Returned',
          value: 'returned'
        },
        {
          name: 'cancelled',
          value: 'cancelled'
        }
      ],
      filters: [],
      searchVal: '',
      paginationObj: {
        currentPage: 1,
        next: null,
        previous: null
      },
      trackingData: {},
      trackingStatus: '',
      searchInProgress: false,
      searchedValue: ' ',
      blockCustomerModal: false,
      customer: {},
      fromDate: '',
      toDate: '',
      criteria: '',
      tableLoader: false
    }
  },
  watch: {
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadShipments()
      }
    },
    'selected' () {
      this.$emit('selected', this.selected)
    }
  },
  methods: {
    async copied (data) {
      try {
        await navigator.clipboard.writeText(data)
      } catch ($e) {
        this.$vs.notify({
          color:'dark',
          title:i18nData[this.$i18n.locale]['cannot copy!'],
          position: 'bottom-center'
        })
      }
    },
    openNotificationFailureData (order) {
      common.openNotificationFailureData(this, order)
    },
    changePageNumber (val) {
      this.paginationObj.currentPage = val === 'next' ? this.paginationObj.currentPage + 1 : this.paginationObj.currentPage - 1
      this.loadShipments(this.paginationObj[val])
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.loadShipments()
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    handleSearchDate (start, end, criteria) {
      this.fromDate = start ? new Date(start).toISOString().split('T')[0] : ''
      this.toDate = end ? new Date(end).toISOString().split('T')[0] : ''
      this.criteria = criteria
      this.loadShipments()
    },
    handleSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadShipments()
    },
    blockCustomer (data) {
      this.customer = data
      this.blockCustomerModal = true
    },
    loadShipments (cursorVal) {
      if (!cursorVal) this.paginationObj.currentPage = 1
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?cursor=${cursorVal ? cursorVal : ''}&limit=${this.maximumItems}&search=${this.searchVal}&${this.filters.includes('on_hold') ? 'on_hold=true' : this.filters.includes('rto_requested') ? 'rto_requested=true' : `status=${this.filters}`}&${this.criteria}_after=${this.fromDate ? this.fromDate : ''}&${this.criteria}_before=${this.toDate ? this.toDate : ''}`
      sendRequest(true, false, this, `api/v1/delivery-orders/${query}`, 'get', null, true,
        (response) => {
          this.shipments = response.data.results
          this.paginationObj.next = response.data.next && new URL(response.data.next).searchParams.get('cursor')
          this.paginationObj.previous = response.data.previous && new URL(response.data.previous).searchParams.get('cursor')
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all' || (this.filterValue === 'rto_requested' && this.filters.includes('rto_requested')) || (this.filterValue === 'on_hold' && this.filters.includes('on_hold'))) {
        this.filters = []
        if (this.filterValue === 'rto_requested' && !this.filters.includes('rto_requested')) {
          this.filters.push('rto_requested')
        }  else if (this.filterValue === 'on_hold' && !this.filters.includes('on_hold')) {
          this.filters.push('on_hold')
        }
      } else if (this.filters.includes('rto_requested')) {
        this.filters.splice(this.filters.indexOf('rto_requested'), 1)
      } else if (this.filters.includes('on_hold')) {
        this.filters.splice(this.filters.indexOf('on_hold'), 1)
      }
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-delivery-orders-view`,
        query: {
          filter: this.filters
        }
      }).catch(() => {})
      this.loadShipments()
    },
    viewShipment (data) {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-delivery-orders`,
        params: {
          type: 'delivery-orders',
          orderID: data.id
        }
      }).catch(() => {})
    },
    cancelShipment (data, sentence, status) {
      this.trackingData = data
      this.trackingStatus = status
      this.$vs.dialog({
        color: 'danger',
        title: i18nData[this.$i18n.locale]['Confirm'],
        text: `${sentence === 'returned' ? i18nData[this.$i18n.locale]['Are you sure you have returned this order to the merchant?'] :  i18nData[this.$i18n.locale]['Are you sure you want to '] + i18nData[this.$i18n.locale][sentence]}`,
        acceptText: i18nData[this.$i18n.locale]['Yes'],
        accept: this.confirmCancelShipment
      })
    },
    confirmCancelShipment () {
      const trackings = [
        {
          order: this.trackingData.id,
          packages: this.trackingData.packages.map(item => item.package.id),
          status: this.trackingStatus
        }
      ]
      sendRequest(false, false, this, 'api/v1/tracking/', 'post', trackings, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Delivery Order'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.loadShipments()
        }
      )
    }
  },
  components: {
    BlockCustomer,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ExportButton,
    PrintLabelButton,
    CancelShipmentsButton,
    TableFilter,
    ShipbluCursorPagination,
    DownloadButton,
    DateFilter
  },
  mounted () {
    this.$emit('setAppClasses', 'shipment-page')
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.loadShipments()
  }
}
export {common}
</script>

<style scoped>
.vs-dialog {
  max-width: 530px;
}
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
